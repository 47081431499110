body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    color: #333333;
}

.App {
    display: flex;
    height: 100vh;
}

.App-content {
    flex-grow: 1;
    padding: 20px;
    padding-left: 320px; /* Make room for the wider sidebar */
    box-sizing: border-box;
}
