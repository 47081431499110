.App-sidebar {
    background-color: #f8f9fa;
    width: 300px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: fixed;
    height: 100%;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.2);
}

.App-sidebar h1 {
    color: #343a40;
    margin-bottom: 40px;
}

.App-sidebar a {
    color: #343a40;
    text-decoration: none;
    margin-bottom: 15px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    background-color: #e9ecef;
    border: 1px solid #dee2e6;
}

.App-sidebar a:hover {
    background-color: #ced4da;
    cursor: pointer;
}
