.TopBar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
}

.TopBarButton {
    background: none;
    border: none;
    color: #343a40;
    padding: 10px 20px;
    border-bottom: 2px solid #343a40;
    text-decoration: none;
    font-weight: 600;
    margin: 0 10px;
    transition: background-color 0.3s ease;
}

.TopBarButton:hover {
    background-color: #e9ecef;
    cursor: pointer;
}
